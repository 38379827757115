<template>
	<div class="space-y-4 mt-4">
		<div class="bg-white rounded-xl border border-gray-100 mb-4 text-gray-600 py-4 px-4">
			<div class="flex items-center">
				<user-profile-picture 
					:user="friend"
					:size="10"
				/>
				<div class="flex-1 ml-4">
					<p class="font-bold">{{ friend.name }}</p>
					<p class="text-sm">
						{{ humanStatus }}
					</p>
				</div>
				<button @click.stop.prevent="removeFromTable()" v-if="!guest">
					<font-awesome-icon icon="fa-solid fa-close" class="text-gray-600 px-2"/>
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import UserProfilePicture from '@/components/UserProfilePicture';
	export default {
		components: {
			UserProfilePicture,
		},
		props: {
			friend: {
				type: Object
			},
			guest: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				isOpen: false
			}
		},
		computed: {
			humanStatus(){
				if (this.friend.status === 'invited') {
					return 'Invitation sent';
				}
				if (this.friend.status === 'incomplete') {
					return 'Waiting for answers...';
				}
				if (this.friend.status === 'complete') {
					return 'Preferences completed';
				}
				return '';
			}
		},
		methods: {
			removeFromTable(){
				this.$store.dispatch('table/removeFromTable', {
					user: this.friend.uuid,
					table: this.$store.state.table.table.uuid
				});
			}
		}
	}
</script>