<template>
	<div class="h-full flex flex-col">
		<TopBar :showMenu="true" :showClose="true"/>
		<ProgressBar :current="1"/>
		<AppTitle :title="tableName"/>
		<div class="pt-2 text-gray-400 flex-grow overflow-scroll" v-if="table">
			<div class="flex items-center">
				<p class="flex-1 text-gray-600 font-bold text-lg mt-4">
					Attendees
				</p>
			</div>
			<div>
				<table-friends-row 
					:friend="user"
					:size="10"
					v-for="user in table.users"
					:key="user.uuid"
					:guest="true"
				/>
			</div>
		</div>
		<div class="w-full py-10 bg-gray-50">
			<div>
				<button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg mb-4" @click="acceptInvite()">
					Accept Invite
				</button>
				<button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="declineInvite()">
					Decline
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import ProgressBar from '@/components/ProgressBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	import TableFriendsRow from '@/components/TableFriendsRow';
	export default {
		components: {
			TopBar,
			AppTitle,
			ProgressBar,
			TableFriendsRow,
		},
		data(){
			return {
				
			}
		},
		computed: {
			currentUser(){
				return this.$store.state.auth.user;
			},
			tableName(){
				return this.table.name.length ? this.table.name : `Table ${this.table.code.toUpperCase()}`
			},
			table(){
				return this.$store.state.table.table;
			},
		},
		methods: {
			acceptInvite(){
				this.$store.dispatch('table/acceptInvite', {
					table_uuid: this.table.uuid,
					user_uuid: this.currentUser.uuid
				}).then( () => {
					this.$router.push({name: 'table-food'})
				});
			},
			declineInvite(){
				this.$store.dispatch('table/declineInvite', {
					table_uuid: this.table.uuid,
					user_uuid: this.currentUser.uuid
				}).then( () => {
					this.$router.push({name: 'dashboard'})
				});
			}
		}
	}
</script>